import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Authenticator, SignUp } from "aws-amplify-react/dist/Auth";

import config from "../config";
import logo from "../assets/logo.svg";

@inject("commonContainer")
@observer
class AccountPage extends Component {
  render() {
    const { commonContainer } = this.props;
    return (
      <div
        style={{
          background: "#231f1f",
          minHeight: "100vh",
          textAlign: "center"
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            height: 150,
            margin: "5px 0px"
          }}
        />
        <Authenticator
          includeGreetings
          // Optionally hard-code an initial state
          authState="signIn"
          // Pass in an already authenticated CognitoUser or FederatedUser object
          // authData="username"
          // Fired when Authentication State changes
          onStateChange={(authState, authData) =>
            authState === "signedIn" ? commonContainer.handleLogin(authData) : null
          }
          // Pass in a message map for error strings
          // errorMessage={myMessageMap}
          // A theme object to override the UI / styling
          theme={{
            container: {
              background: "transparent"
            },
            formContainer: {
              margin: 0
            },
            button: {
              color: "#fff",
              backgroundColor: "#678cc3",
              boxShadow: "0 2px 0 rgba(0,0,0,.045)",
              borderRadius: 4
            },
            a: {
              color: "#678cc3"
            }
          }}
          // Hide specific components within the Authenticator
          // *** Only supported on React/Web (Not React Native)  ***
          hide={[SignUp]}
          // Pass in an aws-exports configuration
          amplifyConfig={{
            Auth: {
              // REQUIRED - Amazon Cognito Region
              region: config.app.auth.region,
              // OPTIONAL - Amazon Cognito User Pool ID
              userPoolId: config.app.auth.userPoolId,
              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
              userPoolWebClientId: config.app.auth.userPoolWebClientId,
              // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
              mandatorySignIn: true
              // OPTIONAL - customized storage object
              // storage: new MyStorage(),
              // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
              // authenticationFlowType: 'USER_PASSWORD_AUTH'
            }
          }}
        />
        <p style={{ color: "white", textAlign: "center", fontSize: 14 }}>
          &copy; {new Date().getFullYear()} {config.app.info.name}. All Rights Reserved.
          <br />
          For Internal Use Only.
        </p>
      </div>
    );
  }
}

export default AccountPage;
