import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

// Ant Design Components
import { List, Popover, Avatar } from "antd";

@inject("commonContainer")
@withRouter
@observer
class ApplicationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      availableApplications: [],
      applicationID: localStorage.getItem("application-id"),
      applicationMenuVisible: false
    };
  }

  componentWillMount() {
    this.fetchApplications();
  }

  setApplication = ({ id, name }) => {
    const { history } = this.props;
    this.setState({ applicationID: id, applicationMenuVisible: false });
    localStorage.setItem("application-id", id);
    localStorage.setItem("application-name", name);
    history.push("/");
  };

  initaliseApplicationID = () => {
    const { applicationID, availableApplications } = this.state;
    if (!applicationID && availableApplications[0]) {
      this.setState({ applicationID: availableApplications[0].id });
      localStorage.setItem("application-id", availableApplications[0].id);
      localStorage.setItem("application-name", availableApplications[0].name);
    }
  };

  fetchApplications = () => {
    const { commonContainer } = this.props;
    commonContainer
      .handleRequest(
        {
          type: "query",
          operation: "listApplications",
          fields: [{ items: ["id", "name"] }]
        },
        {}
      )
      .then(data => {
        const availableApplications = data ? data.data.listApplications.items : [];
        this.setState({ availableApplications, loading: false });
        this.initaliseApplicationID();
        // store the available applications
        localStorage.setItem("available-applications", JSON.stringify(availableApplications));
      });
  };

  render() {
    const {
      loading,
      availableApplications,
      applicationID,
      applicationMenuVisible
    } = this.state;
    const { buttonComponent } = this.props;
    return (
      <Popover
        title="Mobile Applications"
        trigger="click"
        placement="bottomLeft"
        arrowPointAtCenter
        align={{
          targetOffset: ["0%", "20%"]
        }}
        visible={applicationMenuVisible}
        onVisibleChange={visible => this.setState({ applicationMenuVisible: visible })}
        content={
          <div>
            <List
              itemLayout="horizontal"
              loading={loading}
              dataSource={availableApplications}
              renderItem={application => (
                <List.Item
                  onClick={() => this.setApplication(application)}
                  style={{ cursor: "pointer" }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        size="large"
                        style={
                          application.id === applicationID
                            ? {
                                backgroundColor: "#678dc5"
                              }
                            : null
                        }
                      >
                        {application.name.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    title={
                      application.id === applicationID
                        ? `${application.name} (Selected)`
                        : application.name
                    }
                    description={application.description || "Weyo Mobile Application"}
                  />
                </List.Item>
              )}
            />
          </div>
        }
      >
        {buttonComponent}
      </Popover>
    );
  }
}

export default ApplicationSelect;
