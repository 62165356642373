import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { singular } from "pluralize";

// Ant Design Components
import { Button, Icon, Row, Col, Table, Typography, Input } from "antd";

import DashboardDrawer from "./TableDrawer";

@inject("commonContainer", "dashboardContainer")
@observer
class TablePage extends Component {
  componentDidMount() {
    const { dashboardContainer, operations } = this.props;
    dashboardContainer.handleRead(operations);
  }

  getColumnSearchProps = column => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${column.title}`}
          value={selectedKeys[0]}
          onChange={event =>
            setSelectedKeys(event.target.value ? [event.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      let columnValue = record[column.dataIndex];
      if (column.render) {
        columnValue = column.render(record[column.dataIndex], record);
        // Single Component
        if (columnValue.props && columnValue.props.children) {
          columnValue = columnValue.props.children;
        }
        // Multiple Components
        else if (Array.isArray(columnValue)) {
          columnValue = columnValue.map(component => component.props.children);
        }
      }
      return columnValue
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    sorter: (a, b) => {
      let c = a[column.dataIndex];
      let d = b[column.dataIndex];
      if (column.render) {
        c = column.render(a[column.dataIndex], a);
        d = column.render(b[column.dataIndex], b);
        // Single Component
        if (c.props && c.props.children) {
          c = c.props.children;
          d = d.props.children;
        }
        // Multiple Components
        else if (Array.isArray(c)) {
          c = c.map(component => component.props.children);
          d = d.map(component => component.props.children);
        }
      }
      if (c < d) {
        return -1;
      }
      if (c > d) {
        return 1;
      }
      return 0;
    }
  });

  render() {
    const {
      dashboardContainer,
      title,
      componentProps,
      drawerProps,
      operations
    } = this.props;
    return (
      <Fragment>
        <Row>
          <Col xs={12}>
            <Typography.Title level={2}>{title}</Typography.Title>
          </Col>
          <Col xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={() => dashboardContainer.handleRead(operations)}
            >
              <Icon type="reload" />
            </Button>
            <Button
              type="primary"
              onClick={() => dashboardContainer.handleExportCSV(title)}
              style={{ marginLeft: 12 }}
            >
              Export <Icon type="export" />
            </Button>
            {drawerProps && operations.create.allowed ? (
              <Button
                type="primary"
                onClick={() => dashboardContainer.handleOpen(operations)}
                style={{ marginLeft: 12 }}
              >
                {`Create ${singular(title)}`} <Icon type="arrow-right" />
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Table
            rowKey="id"
            scroll={{ x: true }}
            dataSource={dashboardContainer.view.data.items}
            loading={dashboardContainer.view.loading}
            columns={[
              ...componentProps.columns.map(column =>
                Object.assign(column, this.getColumnSearchProps(column))
              )
            ]}
            onRow={record => ({
              onClick: () =>
                drawerProps ? dashboardContainer.handleOpen(operations, record) : null
            })}
          />
          {drawerProps ? (
            <DashboardDrawer
              title={singular(title)}
              operations={operations}
              {...drawerProps}
            />
          ) : null}
        </Row>
      </Fragment>
    );
  }
}

export default TablePage;
