import React, { Component, Fragment } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import {
  Form,
  Row,
  Col,
  Button,
  Icon,
  Table,
  Select,
  Input,
  Upload,
  Modal,
  message,
  Switch
} from "antd";
import { getUploadData, uploadFileToS3 } from "../util";

@inject("commonContainer", "dashboardContainer")
@observer
class AssetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      assetOptions: [],
      assetName: "",
      assetGroup: null,
      isDefault: false
    };
  }

  fetchAssetGroups = () => {
    const {
      commonContainer,
      dashboardContainer: {
        drawer: {
          operations: { readAssetGroups }
        }
      }
    } = this.props;
    const { variables } = readAssetGroups;
    commonContainer.handleRequest(readAssetGroups, variables()).then(data => {
      const options = data.data.listGroups.items.map(group => ({
        title: group.name,
        value: group.id
      }));
      this.setState({ assetOptions: options });
    });
  };

  createAsset = assetInput => {
    const { commonContainer, dashboardContainer, record: { id: resourceId } } = this.props;
    const {
      dashboardContainer: {
        drawer: {
          operations: { createAsset }
        }
      }
    } = this.props;
    const { variables } = createAsset;
    commonContainer.handleRequest(createAsset, variables(assetInput)).then(
      action(() => {
        dashboardContainer.handleSuccessfulAction('Asset creation', true, resourceId);
      })
    );
  };

  deleteAsset = id => {
    const { commonContainer, dashboardContainer, record: { id: resourceId } } = this.props;
    const {
      dashboardContainer: {
        drawer: {
          operations: { deleteAsset }
        }
      }
    } = this.props;
    const { variables } = deleteAsset;

    commonContainer
      .handleRequest(deleteAsset, variables(id))
      .then(
        action(() => {
          dashboardContainer.handleSuccessfulAction('Asset removal', true, resourceId);
        })
      )
      .catch(() => message.error("Delete failed!"));
  };

  componentDidMount = () => {
    this.fetchAssetGroups();
  };

  handleUpload = async () => {
    const { fileList, assetName, assetGroup, isDefault } = this.state;
    const {
      commonContainer,
      record: { id }
    } = this.props;

    this.setState({ uploading: true });

    const file = fileList[0];

    let uploadData = null;

    try {
      const postData = await getUploadData(
        commonContainer,
        file,
        localStorage.getItem("application-name")
      );
      uploadData = await uploadFileToS3(postData, file);
      this.setState({
        uploading: false,
        fileList: []
      });
    } catch (err) {
      message.error("Asset upload to S3 failed!");
    }

    if (uploadData) {
      try {
        const fileUrl = uploadData.prettyUrl;
        await this.createAsset({
          name: assetName,
          file: fileUrl,
          default: isDefault,
          assetResourceId: id,
          assetGroupId: assetGroup
        });
      } catch (err) {
        message.error("Asset creation failed!");
      }
    }
  };

  handleDelete = id => {
    Modal.confirm({
      title: `Are you sure you want to delete this asset`,
      okText: "Delete asset",
      okType: "danger",
      onOk: async () => this.deleteAsset(id)
    });
  };

  render() {
    const { assetName, assetOptions, fileList, assetGroup, uploading } = this.state;
    const { columns, value } = this.props;

    const uploadProps = {
      beforeUpload: file => {
        this.setState({
          assetName: file.name.replace(/\..+$/, ""),
          fileList: [file]
        });
        return false;
      },
      onRemove: () => this.setState({ fileList: [] }),
      fileList
    };

    return (
      <Fragment>
        <Table
          columns={[
            ...columns,
            {
              render: ({ id }) => (
                <Button
                  type="danger"
                  icon="close"
                  shape="circle"
                  size="small"
                  onClick={() => this.handleDelete(id)}
                />
              )
            }
          ]}
          dataSource={value ? value.items : []}
          rowKey="id"
          size="middle"
          pagination={false}
        />
        <Row gutter={16} style={{ margin: 0 }}>
          <Upload {...uploadProps}>
            <Button style={{ margin: "1rem 0" }}>
              Upload Asset
              <Icon type="upload" />
            </Button>
          </Upload>
          {fileList.length !== 0 && (
            <Fragment>
              <Col span={8}>
                <Form.Item label="Asset Name">
                  <Input
                    value={assetName}
                    onChange={e => this.setState({ assetName: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Asset Group">
                  <Select
                    value={assetGroup}
                    placeholder="Select a group"
                    onSelect={group => this.setState({ assetGroup: group })}
                  >
                    {assetOptions.map(option => (
                      <Select.Option value={option.value} key={option.value}>
                        {option.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Default">
                  <Switch onChange={checked => this.setState({ isDefault: checked })} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button
                  style={{ marginTop: "1.75rem" }}
                  onClick={this.handleUpload}
                  loading={uploading}
                  disabled={!assetName || !assetGroup}
                >
                  <Icon type="upload" />
                </Button>
              </Col>
            </Fragment>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default AssetsTable;
