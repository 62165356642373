import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import promiseFinally from "promise.prototype.finally";

// MobX
import { configure } from "mobx";
import { Provider } from "mobx-react";

// MobX Containers
import commonContainer from "./containers/commonContainer";
import dashboardContainer from "./containers/dashboardContainer";

import App from "./App";

const stores = {
  commonContainer,
  dashboardContainer
};

// For easier debugging
window._____APP_STATE_____ = stores; // eslint-disable-line no-underscore-dangle

promiseFinally.shim();
configure({ enforceActions: "observed" });

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
