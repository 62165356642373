import React, { PureComponent } from "react";
import { Table } from "antd";

class SmallTable extends PureComponent {
  render() {
    const { value, columns } = this.props;
    return (
      <Table
        columns={columns}
        dataSource={value ? value.items : []}
        rowKey="id"
        size="small"
      />
    );
  }
}

export default SmallTable;
