import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Ant Design Components
import { Button, Icon, List, Typography, Upload } from "antd";
import { getUploadData, uploadFileToS3 } from "../util";

@inject("commonContainer")
@observer
class AssetUploadPage extends Component {
  state = {
    fileList: [],
    uploading: false,
    uploadedList: [],
    applicationName: localStorage.getItem("application-name")
  };

  handleUpload = () => {
    const { fileList, applicationName } = this.state;
    const { commonContainer } = this.props;

    this.setState({ uploading: true });

    fileList.forEach(async file => {
      const postData = await getUploadData(commonContainer, file, applicationName);
      if (!postData) {
        return;
      }
      uploadFileToS3(postData, file).then(({ prettyUrl }) =>
        this.setState(state => ({
          uploadedList: [...state.uploadedList, prettyUrl]
        }))
      );
    });
    this.setState({
      fileList: [],
      uploading: false
    });
  };

  render() {
    const { title } = this.props;

    const { fileList, uploading, uploadedList } = this.state;

    const props = {
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },
      fileList
    };

    if (localStorage.getItem("application-id")) {
      return (
        <Fragment>
          <Typography.Title level={2}>{title}</Typography.Title>
          <Upload {...props}>
            <Button style={{ margin: "1rem" }}>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
          <Button
            type="primary"
            onClick={this.handleUpload}
            loading={uploading}
            disabled={fileList.length === 0}
            style={{ margin: "1rem" }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
          <List
            header={<Typography.Title level={4}>Uploaded Files</Typography.Title>}
            bordered
            dataSource={uploadedList}
            renderItem={item => (
              <List.Item>
                <Typography.Paragraph copyable>{item}</Typography.Paragraph>
              </List.Item>
            )}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Typography.Title level={2}>{title}</Typography.Title>
        <p>
          An application <b>must</b> be selected first in order to access this page.
        </p>
      </Fragment>
    );
  }
}

export default AssetUploadPage;
