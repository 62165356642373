import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Select } from "antd";

@inject("commonContainer", "dashboardContainer")
@observer
class CategorySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true,
      value: props.value ? props.value.name : null
    };
  }

  componentDidMount = () => {
    this.fetchCategories();
  };

  componentDidUpdate = prevProps => {
    const { props, state } = this;
    if (props.value !== prevProps.value && props.value !== state.value) {
      this.initalise();
    }
  };

  fetchCategories = () => {
    const { commonContainer } = this.props;
    commonContainer
      .handleRequest(
        {
          type: "query",
          operation: "listCategories",
          fields: [{ items: ["id", "name"] }]
        },
        {
          categoryApplicationId: {
            value: localStorage.getItem("application-id"),
            type: "ID",
            required: true
          }
        }
      )
      .then(result => {
        const data = result.data.listCategories.items.map(({ name, id }) => ({
          name,
          id
        }));
        this.setState({
          categories: data,
          loading: false
        });
        this.initalise();
      });
  };

  initalise = () => {
    const { value } = this.props;
    this.setState({
      value: value ? value.id : null
    });
  };

  handleChange = value => {
    const { onChange } = this.props;
    this.setState({
      value
    });
    onChange(value);
  };

  render() {
    const { categories, loading, value } = this.state;
    return (
      <Fragment>
        <Select
          loading={loading}
          value={value}
          style={{ textTransform: "capitalize" }}
          onChange={this.handleChange}
        >
          {categories.map(category => (
            <Select.Option
              key={category.id}
              value={category.id}
              style={{ textTransform: "capitalize" }}
            >
              {category.name.toLowerCase()}
            </Select.Option>
          ))}
        </Select>
      </Fragment>
    );
  }
}

export default CategorySelect;
