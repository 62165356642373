import { observable, action, toJS } from "mobx";

import Auth from "@aws-amplify/auth";

import moment from "moment";
import { unparse } from "papaparse";
import { saveAs } from "file-saver";

import { message, Modal } from "antd";

import commonContainer from "./commonContainer";

class DashboardContainer {
  @observable view = {
    loading: true,
    data: []
  };
  @observable drawer = {
    mode: "create",
    visible: false,
    loading: false,
    data: {},
    operations: {}
  };

  @action async handleOpen(operations, data = null) {
    localStorage.setItem("drawer-data", JSON.stringify(data));
    Auth.currentSession().then(session => {
      localStorage.setItem("temporary-token", session.getAccessToken().getJwtToken());
    });
    this.drawer.mode = data ? "update" : "create";
    this.drawer.data = data || {};
    this.drawer.visible = true;
    this.drawer.operations = operations || {};
  }

  @action handleClose() {
    localStorage.removeItem("drawer-data");
    localStorage.removeItem("temporary-token");
    this.drawer.visible = false;
  }

  @action setViewLoading(loading) {
    this.view.loading = loading;
  }

  @action setDrawerLoading(loading) {
    this.drawer.loading = loading;
  }

  @action async handleRead(operations, onSuccess) {
    // TEMPORARY FIX FOR INITIAL LOGIN
    while (!localStorage.getItem("application-id")) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    this.setViewLoading(true);
    this.view.data = {items: []};

    this.handleReadPaginated(operations, onSuccess)
  }

  @action handleReadPaginated(operations, onSuccess, nextToken) {
    const variables = operations.read.variables()
    if (nextToken) { variables.nextToken = nextToken }
    commonContainer
      .handleRequest(operations.read, variables)
      .then(
        action(result => {
          this.view.data.items = this.view.data.items.concat(result.data[operations.read.operation].items)
          if (result.data[operations.read.operation].nextToken) {
            this.handleReadPaginated(operations, onSuccess, result.data[operations.read.operation].nextToken);
          } else {
            // No more stuff to paginate - done!
            this.setViewLoading(false);
            if (onSuccess) onSuccess.call();
          }
        })
      )
      .catch(() => message.error("Loading failed!"));
  }

  @action handleCreate(data) {
    const { operations } = this.drawer;
    this.setDrawerLoading(true);
    commonContainer
      .handleRequest(operations.create, data)
      .then(result => {
        if (result.errors) {
          message.error(result.errors[0].message);
        } else {
          const newRecordId = result.data[operations.create.operation].id
          this.handleSuccessfulAction('Create', true, newRecordId);
        }
      })
      .catch(() => message.error("Create failed!"))
      .finally(() => this.setDrawerLoading(false));
  }

  @action handleUpdate(variables, keepDrawerOpen) {
    const { operations } = this.drawer;
    this.setDrawerLoading(true);
    commonContainer
      .handleRequest(operations.update, operations.update.variables(variables))
      .then(result => {
        if (result.errors) {
          message.error(result.errors[0].message);
        } else {
          this.handleSuccessfulAction('Update', keepDrawerOpen);
        }
      })
      .catch(() => message.error("Update failed!"))
      .finally(() => this.setDrawerLoading(false));
  }

  @action handleDelete(variables, name = "item") {
    const { operations } = this.drawer;
    Modal.confirm({
      title: `Are you sure you want to delete this ${name}?`,
      okText: "Delete",
      okType: "danger",
      onOk: () => {
        this.setDrawerLoading(true);
        commonContainer
          .handleRequest(operations.delete, operations.delete.variables(variables))
          .then(result => {
            if (result.errors) {
              message.error(result.errors[0].message);
            } else {
              this.handleSuccessfulAction('Delete');
            }
          })
          .catch(() => message.error("Delete failed!"))
          .finally(() => this.setDrawerLoading(false));
      }
    });
  }

  @action handleDuplicate(variables) {
    const { operations } = this.drawer;
    this.setDrawerLoading(true);
    commonContainer
      .handleRequest(operations.duplicate, operations.duplicate.variables(variables))
      .then(() => {
        this.handleSuccessfulAction('Duplicate');
      })
      .catch(() => message.error("Duplicate failed!"))
      .finally(() => this.setDrawerLoading(false));
  }

  @action handleSuccessfulAction(actionName, keepDrawerOpen, reloadRecordId) {
    const { operations } = this.drawer;
    if (!keepDrawerOpen) this.handleClose();

    const onSuccessfulRead = reloadRecordId ? () => {
      const record = this.view.data.items.find(x => x.id === reloadRecordId)
      // Reopen the record for further editing
      if (record) this.handleOpen(operations, record);
    } : null
    this.handleRead(operations, onSuccessfulRead);

    const project = operations.create.operation === 'createProject'
    message.success(`${actionName} was successful${project ? ', updating testing environment..' : '.'}`);
    // update the testing environment
    if (project) commonContainer.handleEnvironmentUpdate('unpublished');
  }

  @action handleExportCSV(title) {
    const data = unparse(toJS(this.view.data).items, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null
    });
    const blob = new Blob([data], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, `${title} - ${moment().format("ddd D MMM YYYY h:mma")}.csv`);
  }
}

export default new DashboardContainer();
