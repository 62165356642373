import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { Upload, Icon, message } from "antd";

import { getUploadData, uploadFileToS3 } from "../util";

@inject("commonContainer")
@observer
class ThumbnailUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imageUrl: null
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.clearState();
    }
  }

  clearState = () =>
    this.setState({
      loading: false,
      imageUrl: null
    });

  beforeUpload = file => {
    const valid = file.type === "image/jpeg" || file.type === "image/png";
    if (!valid) {
      message.error("You can only upload a JPG/PNG file.");
    }
    return valid;
  };

  handleChange = async info => {
    const { commonContainer, onChange } = this.props;

    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      const data = await getUploadData(
        commonContainer,
        info.file,
        localStorage.getItem("application-name")
      );
      uploadFileToS3(data, info.file.originFileObj)
        .then(({ prettyUrl }) => {
          onChange(prettyUrl);
          this.setState({
            imageUrl: prettyUrl
          });
        })
        .catch(() => message.error("Failed to upload image"))
        .finally(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { loading, imageUrl } = this.state;
    const { value } = this.props;
    return (
      <Upload
        listType="picture-card"
        style={{ minWidth: 208 }}
        showUploadList={false}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl || (value && value.startsWith("http")) ? (
          <img src={imageUrl || value} alt="preview" style={{ width: "100%" }} />
        ) : (
          <div>
            <Icon type={loading ? "loading" : "plus"} />
            <div className="ant-upload-text">{loading ? "Uploading" : "Upload"}</div>
          </div>
        )}
      </Upload>
    );
  }
}

export default ThumbnailUpload;
