import React, { forwardRef } from "react";
import { Select } from "antd";

export default forwardRef((props, ref) => (
  <Select
    ref={ref}
    value={props.value}
    onChange={props.onChange}
    style={{ textTransform: "capitalize" }}
    placeholder={props.placeholder}
    disabled={props.disabled}
  >
    {props.options.map((option, index) => (
      <Select.Option value={option} key={index} style={{ textTransform: "capitalize" }}>
        {option.toLowerCase().replace("_", " ")}
      </Select.Option>
    ))}
  </Select>
));
