import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Ant Design Components
import { Col, Drawer, Form, Button, Modal, Row, Select, Spin } from "antd";

@inject("commonContainer", "dashboardContainer")
@observer
class DashboardDrawer extends Component {
  constructor(props) {
    super(props);

    this.currentApplicationId = localStorage.getItem("application-id");
    this.availableProjects = JSON.parse(
      localStorage.getItem("available-applications")
    ) || [{id: this.currentApplicationId, name: 'Same'}];

    this.state = {
      duplicateModalVisible: false,
      selectedApplicationId: this.currentApplicationId
    };
  }

  renderTitle = () => {
    const { dashboardContainer, title } = this.props;
    const { mode } = dashboardContainer.drawer;
    switch (mode) {
      case "create":
        return `Create ${title}`;
      case "read":
        return `View ${title}`;
      case "update":
        return `Update ${title}`;
      default:
        return title;
    }
  };

  duplicateProject = () => {
    const { dashboardContainer } = this.props;
    const { selectedApplicationId } = this.state;
    const { data } = dashboardContainer.drawer;
    dashboardContainer.handleDuplicate({
      id: data.id,
      targetApplicationId: selectedApplicationId
    })
    this.setState({duplicateModalVisible: false})
  }

  render() {
    const { dashboardContainer, form, fields, title, operations } = this.props;
    const { duplicateModalVisible, selectedApplicationId } = this.state;
    const { mode, data, visible, loading } = dashboardContainer.drawer;

    if (fields) {
      return (
        <Drawer
          title={this.renderTitle()}
          width={720}
          onClose={() => dashboardContainer.handleClose()}
          afterVisibleChange={() => form.resetFields()}
          visible={visible}
          bodyStyle={{ padding: 0 }}
        >
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              style={{
                overflow: "scroll",
                maxHeight: "calc(100vh - 108px)",
                padding: 24
              }}
            >
              <Row gutter={16} style={{ margin: 0 }}>
                {fields.map(field => {
                  if (
                    (mode === "create" && field.hideOnCreate) ||
                    (mode === "update" && field.hideOnUpdate)
                  ) {
                    return null;
                  }
                  return (
                    <Col span={field.size || 12} key={field.dataIndex}>
                      <Form.Item label={field.title}>
                        {form.getFieldDecorator(field.saveIndex || field.dataIndex, {
                          initialValue: data[field.dataIndex],
                          rules: field.rules
                        })(
                          <field.render
                            record={data}
                            placeholder={field.title}
                            {...field.renderProps}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </Spin>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            {mode === "create" ? (
              <Button
                onClick={() =>
                  form.validateFields((error, values) => {
                    if (!error) {
                      const result = {};
                      Object.entries(values).forEach(([key, value]) => {
                        if (form.isFieldTouched(key) && key !== "tags") {
                          result[key] = value;
                        }
                      });
                      dashboardContainer.handleCreate(
                        operations.create.variables(result)
                      );
                    }
                  })
                }
                type="primary"
              >
                {`Save and Continue`}
              </Button>
            ) : null}
            {mode === "update" ? (
              <Fragment>
                <Button
                  disabled={!operations.delete.allowed}
                  onClick={() =>
                    dashboardContainer.handleDelete(
                      {
                        id: data.id
                      },
                      title.toLowerCase()
                    )
                  }
                  type="danger"
                  style={{ marginRight: 10 }}
                >
                  {`Delete ${title}`}
                </Button>
                <Button
                  disabled={!(operations.duplicate && operations.duplicate.allowed)}
                  onClick={() => this.setState({duplicateModalVisible: true})}
                  type="primary"
                  style={{ marginRight: 10 }}
                >
                  {`Duplicate ${title}`}
                </Button>
                <Button
                  disabled={!operations.update.allowed}
                  onClick={() =>
                    form.validateFields((error, values) => {
                      if (!error) {
                        const result = {};
                        Object.entries(values).forEach(([key, value]) => {
                          if (form.isFieldTouched(key) && key !== "tags") {
                            result[key] = value;
                          }
                        });
                        dashboardContainer.handleUpdate({
                          id: data.id,
                          ...result
                        });
                      }
                    })
                  }
                  type="primary"
                >
                  {`Update ${title}`}
                </Button>
              </Fragment>
            ) : null}
          </div>
          <Modal
            title="Duplicate Project"
            visible={duplicateModalVisible}
            onOk={() => this.duplicateProject()}
            onCancel={() => this.setState({duplicateModalVisible: false})}
          >
            <Form
              layout="vertical"
            >
              <Form.Item
                label="Target Mobile Application"
                name="application_id"
              >
                <Select
                  defaultValue={selectedApplicationId}
                  onChange={(value) => this.setState({selectedApplicationId: value})}
                >
                  {this.availableProjects.map((project, index) => (
                    <Select.Option
                      value={project.id}
                      key={index}
                    >
                      {`${project.name}${project.id === this.currentApplicationId ? ' (current application)' : ''}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </Drawer>
      );
    }
    return null;
  }
}

export default Form.create()(DashboardDrawer);
