import React, { Fragment } from "react";
import { Typography } from "antd";

export default function() {
  return (
    <Fragment>
      <Typography.Title level={2}>Page Not Found</Typography.Title>
      <p>The requested page was not found.</p>
      <p>
        The page you are looking might have been removed, had its name chnaged, or is
        temporarily unavailable.
      </p>
    </Fragment>
  );
}
