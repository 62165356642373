import React, { Component, Fragment } from "react";

import { Button, Drawer } from "antd";

export default class DecisionsEditorButton extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      reload: 0
    };
  }
  toggleDrawer = () => {
    this.setState(prevState => ({
      visible: !prevState.visible,
      reload: prevState.reload + 1
    }));
  };

  render() {
    const { visible, reload } = this.state;
    const { url } = this.props;
    return (
      <Fragment>
        <Button type="primary" onClick={this.toggleDrawer}>
          Open Decisions Editor
        </Button>
        <Drawer
          title="Decisions Editor"
          width={1218}
          closable
          onClose={this.toggleDrawer}
          visible={visible}
          bodyStyle={{
            backgroundColor: "#fbfbfb",
            padding: 0,
            overflow: "hidden"
          }}
        >
          <iframe
            title="edl-editor"
            key={reload}
            src={url}
            style={{
              width: "100%",
              height: "calc(100vh - 60px)",
              border: 0
            }}
          />
        </Drawer>
      </Fragment>
    );
  }
}
