import React from "react";

// Text Formatting Libraries
import moment from "moment";

// Ant Design Components
import { Input, Tag, Icon, Radio } from "antd";

// Page Components
import TablePage from "./views/TablePage";
import SettingsPage from "./views/SettingsPage";
import AssetUploadPage from "./views/AssetUploadPage";

// Drawer Components
import DecisionsEditorButton from "./components/DecisionsEditorButton";
import Select from "./components/Select";
import Switch from "./components/Switch";
import TreeSelect from "./components/TreeSelect";
// import CategoriesTable from "./components/CategoriesTable";
import AssetsTable from "./components/AssetsTable";
import MetadataInput from "./components/MetadataInput";
import SmallTable from "./components/SmallTable";
import CategorySelect from "./components/CategorySelect";
import ThumbnailUpload from "./components/ThumbnailUpload";

moment.updateLocale(moment.locale(), { invalidDate: "Never" });

// Variables
const applicationID = () => localStorage.getItem("application-id");
const productionEnvironment = process.env.REACT_APP_ENV === "production";
const GRAPHQL_QUERY_LIMIT = 1000; // by default it's 100, let's increase it

// Custom Components
const columnBoolean = text =>
  text ? <Tag color="green">True</Tag> : <Tag color="red">False</Tag>;

const columnTags = value =>
  value
    ? value.items.map(({ tag, id, name }) =>
        tag ? (
          <Tag key={tag.id} style={{ marginRight: 8 }} color="blue">
            {tag.name}
          </Tag>
        ) : (
          <Tag key={id} style={{ marginRight: 8 }} color="blue">
            {name}
          </Tag>
        )
      )
    : null;

export default {
  app: {
    info: {
      name: "Weyo Technology"
    },
    api: {
      url: productionEnvironment
        ? "https://api.weyo.world/graphql"
        : "https://api.dev.weyo.world/graphql",
      region: productionEnvironment ? "us-east-1" : "us-east-2"
    },
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS",
      userPoolId: productionEnvironment ? "us-east-1_h7OSWlx35" : "us-east-2_k5MhqkJeV",
      userPoolWebClientId: productionEnvironment
        ? "76d3vhmdvqja8eg2370bs7fp9o"
        : "27us3ldl9bv3g64ct58ak0hufc",
      region: productionEnvironment ? "us-east-1" : "us-east-2"
    }
  },
  views: [
    {
      title: "Projects",
      icon: "video-camera",
      component: TablePage,
      componentProps: {
        columns: [
          { dataIndex: "id", title: "Reference ID" },
          {
            dataIndex: "name",
            title: "Project Name",
            onCell: () => ({ style: { textTransform: "capitalize" } })
          },
          {
            render: text => (text ? text.toLowerCase().replace("_", " ") : null),
            dataIndex: "type",
            title: "Project Type",
            onCell: () => ({ style: { textTransform: "capitalize" } })
          },
          {
            render: text => moment(text, "YYYY-MM-DDTHH:mm.sssZ").fromNow(),
            dataIndex: "createdAt",
            title: "Project Created"
          },
          {
            render: text => moment(text, "YYYY-MM-DDTHH:mm.sssZ").fromNow(),
            dataIndex: "updatedAt",
            title: "Project Updated"
          },
          {
            render: columnBoolean,
            dataIndex: "published",
            title: "Published"
          },
          {
            render: columnTags,
            dataIndex: "tags",
            title: "Project Tags"
          }
        ]
      },
      drawerProps: {
        fields: [
          {
            render: Input,
            dataIndex: "id",
            title: "Reference ID",
            renderProps: { disabled: true },
            hideOnCreate: true
          },
          {
            render: Input,
            dataIndex: "name",
            title: "Project Name",
            rules: [
              {
                required: true,
                message: "Project name is required"
              },
              {
                max: 50,
                message: "Project name must be less than 50 characters"
              }
            ]
          },
          {
            render: Input.TextArea,
            dataIndex: "description",
            title: "Project Description",
            size: 24
          },
          {
            render: Select,
            renderProps: { options: ["LANDSCAPE", "PORTRAIT"] },
            dataIndex: "orientation",
            title: "Project Orientation",
            rules: [
              {
                required: true,
                message: "Project orientation is required"
              }
            ]
          },
          {
            render: Select,
            renderProps: {
              options: ["SONG", "GAME", "VIDEO", "MULTIFACE_SONG", "DRESS_UP", "CUSTOM"]
            },
            dataIndex: "type",
            title: "Project Type",
            rules: [
              {
                required: true,
                message: "Project type is required"
              }
            ]
          },
          {
            render: TreeSelect,
            renderProps: {
              mode: "project"
            },
            dataIndex: "tags",
            title: "Project Tags",
            hideOnCreate: true,
            size: 24
          },
          {
            render: Switch,
            dataIndex: "published",
            title: "Project Published"
          },
          {
            render: DecisionsEditorButton,
            renderProps: {
              url: productionEnvironment
                ? "https://manage.weyo.world/editor/index.html"
                : "https://manage.dev.weyo.world/editor/index.html"
            },
            dataIndex: "decisions",
            title: "Project Decisions",
            hideOnCreate: true
          },
          {
            render: ThumbnailUpload,
            dataIndex: "thumbnail",
            title: "Project Thumbnail",
            size: 24
          },
          {
            render: MetadataInput,
            dataIndex: "metadata",
            title: "Project Metadata",
            size: 24
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "resource.id",
                  title: "Resource ID"
                },
                {
                  dataIndex: "resource.name",
                  title: "Resource Name"
                }
              ]
            },
            dataIndex: "resources",
            title: "Associated Resources",
            size: 24,
            hideOnCreate: true
          }
        ]
      },
      operations: {
        read: {
          allowed: true,
          type: "query",
          operation: "listProjects",
          fields: [
            {
              items: [
                "id",
                "name",
                "description",
                "createdAt",
                "updatedAt",
                "published",
                "orientation",
                "type",
                "thumbnail",
                "decisions",
                "metadata",
                {
                  tags: [
                    {
                      items: ["id", { tag: ["id", "name"] }, { project: ["id", "name"] }]
                    }
                  ]
                },
                {
                  resources: [
                    {
                      items: [
                        "id",
                        {
                          resource: ["id", "name"]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          variables: () => ({
            projectApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            },
            limit: {
              value: GRAPHQL_QUERY_LIMIT,
            }
          })
        },
        create: {
          allowed: true,
          type: "mutation",
          operation: "createProject",
          fields: ["id"],
          variables: value => ({
            input: {
              value: {
                projectApplicationId: applicationID(),
                genre: [],
                ...value
              },
              type: "CreateProjectCustomInput",
              required: true
            }
          })
        },
        update: {
          allowed: true,
          type: "mutation",
          operation: "updateProject",
          fields: ["id"],
          variables: value => ({
            input: {
              value: {
                projectApplicationId: applicationID(),
                genre: [],
                ...value
              },
              type: "UpdateProjectCustomInput",
              required: true
            }
          })
        },
        delete: {
          allowed: true,
          type: "mutation",
          operation: "deleteProject",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DeleteProjectInput",
              required: true
            }
          })
        },
        duplicate: {
          allowed: true,
          type: "mutation",
          operation: "duplicateProject",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DuplicateProjectInput",
              required: true
            }
          })
        }
      }
    },
    {
      title: "Resources",
      icon: "picture",
      component: TablePage,
      componentProps: {
        columns: [
          { dataIndex: "id", title: "Reference ID" },
          { dataIndex: "name", title: "Resource Name" },
          {
            render: text => (text ? text.toLowerCase().replace("_", " ") : null),
            dataIndex: "type",
            title: "Resource Type",
            onCell: () => ({ style: { textTransform: "capitalize" } })
          },
          {
            render: text => moment(text, "YYYY-MM-DDTHH:mm.sssZ").fromNow(),
            dataIndex: "createdAt",
            title: "Resource Created"
          },
          {
            render: text => moment(text, "YYYY-MM-DDTHH:mm.sssZ").fromNow(),
            dataIndex: "updatedAt",
            title: "Resource Updated"
          },
          {
            render: assets => (assets ? assets.items.length : null),
            dataIndex: "assets",
            title: "Number of Assets"
          },
          {
            render: columnTags,
            dataIndex: "tags",
            title: "Resource Tags"
          }
        ]
      },
      drawerProps: {
        fields: [
          {
            render: Input,
            dataIndex: "id",
            title: "Reference ID",
            renderProps: { disabled: true },
            hideOnCreate: true
          },
          {
            render: Input,
            dataIndex: "name",
            title: "Resource Name",
            rules: [
              {
                required: true,
                message: "Resource name is required"
              }
            ]
          },
          {
            render: Input.TextArea,
            dataIndex: "description",
            title: "Resource Description",
            size: 24
          },
          {
            render: TreeSelect,
            dataIndex: "tags",
            title: "Resource Tags",
            hideOnCreate: true,
            size: 24,
            renderProps: {
              mode: "resource"
            }
          },
          {
            render: Select,
            renderProps: {
              options: [
                "MASK",
                "MOVIE",
                "AUDIO",
                "STICKER",
                "PARTICLES",
                "IMAGE",
                "SOUND_EFFECT",
                "TRACKED_FACE",
                "SCENE",
                "MESH"
              ]
            },
            dataIndex: "type",
            title: "Resource Type",
            hideOnUpdate: true,
            rules: [
              {
                required: true,
                message: "Resource type is required"
              }
            ]
          },
          {
            render: Select,
            renderProps: {
              disabled: true,
              options: [
                "MASK",
                "MOVIE",
                "AUDIO",
                "STICKER",
                "PARTICLES",
                "IMAGE",
                "SOUND_EFFECT",
                "TRACKED_FACE",
                "SCENE",
                "MESH"
              ]
            },
            dataIndex: "type",
            title: "Resource Type",
            hideOnCreate: true,
            rules: [
              {
                required: true,
                message: "Resource type is required"
              }
            ]
          },
          {
            render: MetadataInput,
            dataIndex: "metadata",
            title: "Resource Metadata",
            size: 24
          },
          {
            render: AssetsTable,
            renderProps: {
              columns: [
                {
                  title: "Default",
                  dataIndex: "default",
                  render: value => <Radio checked={value} disabled />
                },
                {
                  title: "Asset Name",
                  dataIndex: "name"
                },
                {
                  title: "Group Name",
                  dataIndex: "group.name"
                },
                {
                  title: "URL",
                  render: ({ file }) => (
                    <a href={file} title={file}>
                      <Icon type="download" />
                    </a>
                  )
                }
              ]
            },
            dataIndex: "assets",
            title: "Resource Assets",
            size: 24,
            hideOnCreate: true
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "project.id",
                  title: "Project ID"
                },
                {
                  dataIndex: "project.name",
                  title: "Project Name"
                }
              ]
            },
            dataIndex: "projects",
            title: "Associated Projects",
            size: 24,
            hideOnCreate: true
          }
        ]
      },
      operations: {
        create: {
          allowed: true,
          type: "mutation",
          operation: "createResource",
          fields: ["id"],
          variables: value => ({
            input: {
              value: {
                resourceApplicationId: applicationID(),
                ...value
              },
              type: "CreateResourceInput",
              required: true
            }
          })
        },
        read: {
          allowed: true,
          type: "query",
          operation: "listResources",
          fields: [
            {
              items: [
                "id",
                "name",
                "description",
                "type",
                "createdAt",
                "updatedAt",
                "metadata",
                {
                  assets: [
                    {
                      items: ["id", "name", "file", { group: ["id", "name"] }, "default"]
                    }
                  ]
                },
                {
                  tags: [
                    {
                      items: ["id", { tag: ["id", "name"] }]
                    }
                  ]
                },
                {
                  projects: [
                    {
                      items: [
                        "id",
                        {
                          project: ["id", "name"]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          variables: () => ({
            resourceApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            },
            limit: {
              value: GRAPHQL_QUERY_LIMIT,
            }
          })
        },
        update: {
          allowed: true,
          type: "mutation",
          operation: "updateResource",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "UpdateResourceInput",
              required: true
            }
          })
        },
        delete: {
          allowed: true,
          type: "mutation",
          operation: "deleteResource",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DeleteResourceInput",
              required: true
            }
          })
        },
        readAssetGroups: {
          allowed: true,
          type: "query",
          operation: "listGroups",
          fields: [
            {
              items: ["id", "name"]
            }
          ],
          variables: () => ({
            groupApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            }
          })
        },
        createAsset: {
          allowed: true,
          type: "mutation",
          operation: "createAsset",
          fields: ["id", "name"],
          variables: value => ({
            input: {
              value,
              type: "CreateAssetInput",
              required: true
            }
          })
        },
        deleteAsset: {
          allowed: true,
          type: "mutation",
          operation: "deleteAsset",
          fields: ["id"],
          variables: id => ({
            input: {
              required: true,
              type: "DeleteAssetInput",
              value: { id }
            }
          })
        }
      }
    },
    {
      title: "Tags",
      icon: "tags",
      component: TablePage,
      componentProps: {
        columns: [
          { dataIndex: "id", title: "Reference ID" },
          {
            render: name => <Tag color="blue">{name}</Tag>,
            dataIndex: "name",
            title: "Tag Name"
          },
          {
            render: category => (category ? category.name : null),
            dataIndex: "category",
            title: "Tag Category"
          },
          {
            render: projects => (projects ? projects.items.length : null),
            dataIndex: "projects",
            title: "Number of Projects"
          },
          {
            render: resources => (resources ? resources.items.length : null),
            dataIndex: "resources",
            title: "Number of Resources"
          }
        ]
      },
      drawerProps: {
        fields: [
          {
            render: Input,
            dataIndex: "id",
            title: "Reference ID",
            renderProps: { disabled: true },
            hideOnCreate: true
          },
          {
            render: Input,
            dataIndex: "name",
            title: "Tag Name",
            rules: [
              {
                required: true,
                message: "Tag name is required"
              }
            ]
          },
          {
            render: CategorySelect,
            dataIndex: "category",
            saveIndex: "tagCategoryId",
            title: "Tag Category",
            rules: [
              {
                required: true,
                message: "Tag category is required"
              }
            ]
          },
          {
            render: MetadataInput,
            dataIndex: "metadata",
            title: "Tag Metadata",
            size: 24
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "project.id",
                  title: "Project ID"
                },
                {
                  dataIndex: "project.name",
                  title: "Project Name"
                }
              ]
            },
            dataIndex: "projects",
            title: "Associated Projects",
            size: 24,
            hideOnCreate: true
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "resource.id",
                  title: "Resource ID"
                },
                {
                  dataIndex: "resource.name",
                  title: "Resource Name"
                }
              ]
            },
            dataIndex: "resources",
            title: "Associated Resources",
            size: 24,
            hideOnCreate: true
          }
        ]
      },
      operations: {
        read: {
          allowed: true,
          type: "query",
          operation: "listTags",
          fields: [
            {
              items: [
                "id",
                "name",
                "metadata",
                {
                  category: ["id", "name"]
                },
                {
                  projects: [
                    {
                      items: ["id", { project: ["id", "name"] }]
                    }
                  ]
                },
                {
                  resources: [
                    {
                      items: ["id", { resource: ["id", "name"] }]
                    }
                  ]
                }
              ]
            }
          ],
          variables: () => ({
            tagApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            },
            limit: {
              value: GRAPHQL_QUERY_LIMIT,
            }
          })
        },
        create: {
          allowed: true,
          type: "mutation",
          operation: "createTag",
          fields: ["id", "name"],
          variables: value => ({
            input: {
              value: {
                tagApplicationId: applicationID(),
                ...value
              },
              type: "CreateTagInput",
              required: true
            }
          })
        },
        update: {
          allowed: true,
          type: "mutation",
          operation: "updateTag",
          fields: ["id", "name"],
          variables: value => ({
            input: {
              value,
              type: "UpdateTagInput",
              required: true
            }
          })
        },
        delete: {
          allowed: true,
          type: "mutation",
          operation: "deleteTag",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DeleteTagInput",
              required: true
            }
          })
        }
      }
    },
    {
      title: "Tag Categories",
      icon: "apartment",
      component: TablePage,
      componentProps: {
        columns: [
          { dataIndex: "id", title: "Reference ID" },
          { dataIndex: "name", title: "Category Name" },
          {
            render: columnTags,
            dataIndex: "tags",
            title: "Category Tags"
          }
        ]
      },
      drawerProps: {
        fields: [
          {
            render: Input,
            dataIndex: "id",
            title: "Reference ID",
            renderProps: { disabled: true },
            hideOnCreate: true
          },
          {
            render: Input,
            dataIndex: "name",
            title: "Category Name",
            rules: [
              {
                required: true,
                message: "Category name is required"
              }
            ]
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "id",
                  title: "Tag ID"
                },
                {
                  dataIndex: "name",
                  title: "Tag Name"
                }
              ]
            },
            dataIndex: "tags",
            title: "Associated Tags",
            size: 24,
            hideOnCreate: true
          }
          // {
          //   render: CategoriesTable,
          //   renderProps: {
          //     columns: [
          //       {
          //         title: "Reference ID",
          //         dataIndex: "id"
          //       },
          //       {
          //         title: "Tag Name",
          //         dataIndex: "name"
          //       }
          //     ]
          //   },
          //   size: 24,
          //   dataIndex: "tags",
          //   title: "Category Tags",
          //   hideOnCreate: true
          // }
        ]
      },
      operations: {
        read: {
          allowed: true,
          type: "query",
          operation: "listCategories",
          fields: [
            {
              items: ["id", "name", { tags: [{ items: ["id", "name", "metadata"] }] }]
            }
          ],
          variables: () => ({
            categoryApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            },
            limit: {
              value: GRAPHQL_QUERY_LIMIT,
            }
          })
        },
        create: {
          allowed: true,
          type: "mutation",
          operation: "createCategory",
          fields: ["id", "name"],
          variables: value => ({
            input: {
              value: {
                categoryApplicationId: applicationID(),
                ...value
              },
              type: "CreateCategoryInput",
              required: true
            }
          })
        },
        update: {
          allowed: true,
          type: "mutation",
          operation: "updateCategory",
          fields: ["id", "name"],
          variables: value => ({
            input: {
              value,
              type: "UpdateCategoryInput",
              required: true
            }
          })
        },
        delete: {
          allowed: true,
          type: "mutation",
          operation: "deleteCategory",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DeleteCategoryInput",
              required: true
            }
          })
        }
      }
    },
    {
      title: "Asset Groups",
      icon: "build",
      component: TablePage,
      componentProps: {
        columns: [
          { dataIndex: "id", title: "Reference ID" },
          { dataIndex: "name", title: "Asset Group Name" },
          {
            render: assets => (assets ? assets.items.length : null),
            dataIndex: "assets",
            title: "Number of Assets"
          }
        ]
      },
      drawerProps: {
        fields: [
          {
            render: Input,
            dataIndex: "id",
            title: "Reference ID",
            renderProps: { disabled: true },
            hideOnCreate: true
          },
          {
            render: Input,
            dataIndex: "name",
            title: "Group Name",
            rules: [
              {
                required: true,
                message: "Group name is required"
              }
            ]
          },
          {
            render: SmallTable,
            renderProps: {
              columns: [
                {
                  dataIndex: "id",
                  title: "Asset ID"
                },
                {
                  dataIndex: "name",
                  title: "Asset Name",
                  render: (value, record) => <a href={record.file}>{value}</a>,
                  ellipsis: true
                }
              ]
            },
            dataIndex: "assets",
            title: "Associated Assets",
            size: 24,
            hideOnCreate: true
          }
        ]
      },
      operations: {
        read: {
          allowed: true,
          type: "query",
          operation: "listGroups",
          fields: [
            {
              items: [
                "id",
                "name",
                { assets: [{ items: ["id", "name", "file", { resource: ["id"] }] }] }
              ]
            }
          ],
          variables: () => ({
            groupApplicationId: {
              value: applicationID(),
              type: "ID",
              required: true
            },
            limit: {
              value: GRAPHQL_QUERY_LIMIT,
            }
          })
        },
        create: {
          allowed: true,
          type: "mutation",
          operation: "createGroup",
          fields: ["id"],
          variables: value => ({
            input: {
              value: {
                groupApplicationId: applicationID(),
                ...value
              },
              type: "CreateGroupInput",
              required: true
            }
          })
        },
        update: {
          allowed: true,
          type: "mutation",
          operation: "updateGroup",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "UpdateGroupInput",
              required: true
            }
          })
        },
        delete: {
          allowed: true,
          type: "mutation",
          operation: "deleteGroup",
          fields: ["id"],
          variables: value => ({
            input: {
              value,
              type: "DeleteGroupInput",
              required: true
            }
          })
        }
      }
    },
    {
      title: "Uploader",
      icon: "cloud-upload",
      component: AssetUploadPage
    },
    {
      title: "Settings",
      icon: "setting",
      component: SettingsPage
    }
  ]
};
