import axios from "axios";
import uuidv4 from "uuid/v4";
import slugify from "slugify";

const ASSETS_BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://assets.weyo.world"
    : "https://assets.dev.weyo.world";

const uploadFileToS3 = async (postData, file) => {
  const formData = new FormData();
  Object.keys(postData.fields).forEach(key => {
    formData.append(key, postData.fields[key]);
  });
  const {
    url,
    fields: { key }
  } = postData;
  formData.append("file", file);
  const uploadData = {
    url,
    key,
    prettyUrl: `${ASSETS_BASE_URL}/${key}`
  };

  return axios.post(url, formData).then(() => Promise.resolve(uploadData));
};

const getUploadData = async (container, file, applicationName) => {
  const response = await container.handleRequest(
    {
      type: "query",
      operation: "uploadAsset"
    },
    {
      filename: {
        value: `${uuidv4()}-${slugify(file.name, { lower: true })}`,
        required: true
      },
      prefix: {
        value: applicationName,
        required: true
      }
    }
  );
  return JSON.parse(response.data.uploadAsset);
};

export { getUploadData, uploadFileToS3 };
