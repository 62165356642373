import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import slugify from "slugify";

// Ant Design Components
import { Typography, Button, Input, List, Card, Icon, Modal } from "antd";

@inject("commonContainer")
@observer
class SettingsPage extends Component {
  updateEnvironment = async prefix => {
    const { commonContainer } = this.props;
    commonContainer.handleEnvironmentUpdate(prefix, true);
  };

  render() {
    const { title } = this.props;
    const domainName =
      process.env.REACT_APP_ENV === "production" ? "weyo.world" : "dev.weyo.world";
    const applicationName = slugify(localStorage.getItem("application-name") || "", {
      lower: true
    });
    const environments = [
      {
        name: "Production",
        computedURL: `https://computed.${domainName}/v1/${applicationName}/`,
        resourcesURL: `https://assets.${domainName}/${applicationName}/`,
        description: (
          <div>
            Compute <b>published</b> projects, resources and categories. This action will
            update the <b>production</b> environment.
            <br />
            <br />
            <b>
              Please ensure you have tested all the published projects in staging first
              before proceeding.
            </b>
          </div>
        ),
        buttonProps: {
          type: "danger"
        },
        confirm: {
          title: "Are you sure you wish to proceed?",
          content:
            "Are you sure you want to publish the application, this will update the live application.",
          function: () => this.updateEnvironment("production")
        }
      },
      {
        name: "Staging",
        computedURL: `https://computed.${domainName}/v1/${applicationName}/staging/`,
        resourcesURL: `https://assets.${domainName}/${applicationName}/`,
        description: (
          <div>
            Compute <b>published</b> projects, resources and categories. This action will
            update the <b>staging</b> environment.
          </div>
        ),
        buttonProps: {
          type: "primary"
        },
        confirm: {
          title: "Are you sure you wish to proceed?",
          content:
            "This will publish the responses to the staging application, this is used to assess the content before publishing to production.",
          function: () => this.updateEnvironment("staging")
        }
      },
      {
        name: "Testing",
        computedURL: `https://computed.${domainName}/v1/${applicationName}/unpublished/`,
        resourcesURL: `https://assets.${domainName}/${applicationName}/`,
        description: (
          <div>
            Compute <b>all</b> projects, resources and categories. This action will update
            the <b>testing</b> environment.
          </div>
        ),
        buttonProps: {
          type: "primary"
        },
        confirm: {
          title: "Are you sure you wish to proceed?",
          content:
            "This will publish the responses to the testing application, this is used for previewing projects and resources in progress.",
          function: () => this.updateEnvironment("unpublished")
        }
      }
    ];

    if (applicationName) {
      return (
        <Fragment>
          <Typography.Title level={2}>{title}</Typography.Title>
          <Typography.Title level={3}>Application Environments</Typography.Title>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 3,
              xxl: 3
            }}
            dataSource={environments}
            renderItem={item => (
              <List.Item>
                <Card
                  title={
                    <div style={{ display: "flex" }}>
                      <Icon
                        type="code-sandbox-circle"
                        theme="filled"
                        style={{
                          fontSize: 24,
                          color: "#47a591",
                          marginRight: 8
                        }}
                      />
                      {item.name} Environment
                    </div>
                  }
                >
                  <Typography.Text strong style={{ display: "block", marginBottom: 8 }}>
                    Computed API Responses URL
                  </Typography.Text>
                  <Input
                    value={item.computedURL}
                    disabled
                    style={{ display: "block", marginBottom: 12 }}
                  />
                  <Typography.Text strong style={{ display: "block", marginBottom: 8 }}>
                    Assets URL
                  </Typography.Text>
                  <Input
                    value={item.resourcesURL}
                    disabled
                    style={{ display: "block", marginBottom: 12 }}
                  />
                  <Typography.Text strong style={{ display: "block", marginBottom: 8 }}>
                    Previous Versions
                  </Typography.Text>
                  <Typography.Text
                    style={{ display: "block", marginBottom: 8 }}
                  ></Typography.Text>
                  <Button disabled style={{ display: "block", marginBottom: 12 }}>
                    Load Previous Version
                  </Button>
                  <Typography.Text strong style={{ display: "block", marginBottom: 8 }}>
                    Update Environment
                  </Typography.Text>
                  <Typography.Text style={{ display: "block", marginBottom: 8 }}>
                    {item.description}
                  </Typography.Text>
                  <Button
                    {...item.buttonProps}
                    onClick={() => {
                      Modal.confirm({
                        title: item.confirm.title,
                        content: item.confirm.content,
                        onOk() {
                          item.confirm.function();
                        }
                      });
                    }}
                    style={{ display: "block" }}
                  >
                    Update {item.name} Environment
                  </Button>
                </Card>
              </List.Item>
            )}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Typography.Title level={2}>{title}</Typography.Title>
        <p>
          An application <b>must</b> be selected first in order to access this page.
        </p>
      </Fragment>
    );
  }
}

export default SettingsPage;
