import { observable, action } from "mobx";

import AWSAppSyncClient from "aws-appsync";
import Auth from "@aws-amplify/auth";

import { gql } from "apollo-boost";
import { query, mutation } from "gql-query-builder";

import { message } from "antd";
import config from "../config";

class CommonContainer {
  @observable authData = {};
  @observable client = new AWSAppSyncClient({
    url: config.app.api.url,
    region: config.app.api.region,
    auth: {
      type: config.app.auth.type,
      apiKey: config.app.auth.userPoolWebClientId,
      jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
    disableOffline: true,
    cacheOptions: {
      addTypename: false
    }
  });

  @action handleLogin(authData) {
    this.authData = authData;
  }

  @action handleLogout() {
    Auth.signOut().then(
      action(() => {
        localStorage.removeItem("application-id");
        this.authData = {};
      })
    );
  }

  @action handleEnvironmentUpdate(prefix, showUpdateStartMessage) {
    if (showUpdateStartMessage) message.info("Updating environment, please wait...");
    this
      .handleRequest(
        {
          type: "query",
          operation: "computeResponses",
          fields: null
        },
        {
          applicationId: {
            value: localStorage.getItem("application-id"),
            required: true
          },
          filter: {
            value: prefix !== "unpublished",
            required: true
          },
          prefix: {
            value: prefix === "production" ? "" : prefix,
            required: false
          }
        }
      )
      .then(data =>
        data.data.computeResponses === "SUCCESS"
          ? message.success("Environment successfully updated.")
          : message.error("Updating environment failed - Internal Server Error")
      )
      .catch((error) => {
        message.error(`Update failed, error message: ${error.message}`);
        // console.error(error);
      });

  }

  @action handleRequest({ type, operation, fields }, variables = {}) {
    switch (type) {
      case "query":
        return this.handleQuery({
          operation,
          fields,
          variables
        });
      case "mutation":
        return this.handleMutation({
          operation,
          fields,
          variables
        });
      default:
        return null;
    }
  }

  @action handleQuery({ operation, fields, variables }) {
    const generate = query({ operation, fields, variables });
    return this.client
      .query({
        query: gql([generate.query.replace(" {  }", "").replace("items", "nextToken, items")]),
        variables: generate.variables,
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      })
      .catch(error => {
        if (error.networkError ? error.networkError.statusCode === 401 : false) {
          this.handleLogout();
        }
      });
  }

  @action handleMutation({ operation, fields, variables }) {
    const generate = mutation({ operation, fields, variables });
    return this.client
      .mutate({
        mutation: gql([generate.query]),
        variables: generate.variables,
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      })
      .catch(error => {
        if (error.networkError ? error.networkError.statusCode === 401 : false) {
          this.handleLogout();
        }
      });
  }
}

export default new CommonContainer();
