import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { TreeSelect as AntTreeSelect, Button, message } from "antd";

@inject("commonContainer", "dashboardContainer")
@observer
class TreeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      selectedRelationships: [],
      originalRelationships: [],
      loading: true
    };
  }

  componentDidMount = () => {
    this.fetchCategories();
    this.initaliseRelationships();
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.initaliseRelationships();
    }
  }

  initaliseRelationships = () => {
    const { value, record, mode } = this.props;
    const relationships = value.items.map(item => ({
      id: item.id,
      tag: item.tag.id,
      [mode]: record.id
    }));
    this.setState({
      selectedRelationships: relationships,
      originalRelationships: relationships
    });
  };

  onChange = relationships => {
    const { mode, record } = this.props;
    const { selectedRelationships } = this.state;

    const existingRelationships = selectedRelationships.filter(item =>
      relationships.includes(item.tag)
    );

    const newRelationships = relationships
      .filter(tag => !selectedRelationships.map(item => item.tag).includes(tag))
      .map(tag => ({ id: null, tag, [mode]: record.id }));

    this.setState({
      selectedRelationships: [...existingRelationships, ...newRelationships]
    });
  };

  handleSave = async () => {
    const { originalRelationships, selectedRelationships } = this.state;
    const { dashboardContainer, mode, record } = this.props;

    this.setState({ loading: true });

    if (originalRelationships.sort() === selectedRelationships.sort()) {
      message.info("No changes made.");
    } else {
      // Delete Relationships
      const tagsToRemove = originalRelationships
        .filter(
          originalRelationship =>
            !selectedRelationships.some(
              newRelationship => newRelationship.tag === originalRelationship.tag
            )
        )

      for (const relationship of tagsToRemove) {
        if (mode === "project") await this.removeRelationshipFromProject(relationship);
        if (mode === "resource") await this.removeRelationshipFromResource(relationship);
      }

      const tagsToCreate = selectedRelationships
        .filter(
          newRelationship =>
            !originalRelationships.some(
              originalRelationship => newRelationship.tag === originalRelationship.tag
            )
        )
      for (const relationship of tagsToCreate) {
        if (mode === "project") await this.addRelationshipToProject(relationship);
        if (mode === "resource") await this.addRelationshipToResource(relationship);
      }

      // Update project (updates the testing environment too)
      dashboardContainer.handleUpdate({id: record.id}, true);
    }

    this.setState(prevState => ({
      loading: false,
      originalRelationships: prevState.selectedRelationships
    }));
  };

  fetchCategories = () => {
    const { commonContainer } = this.props;
    commonContainer
      .handleRequest(
        {
          type: "query",
          operation: "getApplication",
          fields: [
            "id",
            "name",
            {
              categories: [
                {
                  items: ["id", "name", { tags: [{ items: ["id", "name", "metadata"] }] }]
                }
              ]
            }
          ]
        },
        {
          id: {
            value: localStorage.getItem("application-id"),
            type: "ID",
            required: true
          }
        }
      )
      .then(result => {
        const data = result.data.getApplication.categories.items
          .filter(category => category.tags.items.length >= 1)
          .map(category => ({
            title: category.name,
            value: category.id,
            key: category.id,
            children: category.tags.items.map(tag => ({
              title: tag.name,
              value: tag.id,
              key: tag.id
            }))
          }));
        this.setState({
          categories: data,
          loading: false
        });
      });
  };

  addRelationshipToProject = ({ tag, project }) => {
    const { commonContainer } = this.props;
    return commonContainer
      .handleRequest(
        {
          type: "mutation",
          operation: "createProjectTagRelationship",
          fields: ["id", { tag: ["id", "name"] }]
        },
        {
          input: {
            value: {
              projectTagRelationshipTagId: tag,
              projectTagRelationshipProjectId: project
            },
            type: "CreateProjectTagRelationshipInput",
            required: true
          }
        }
      )
      .then(data => {
        message.success(
          `Successfully Added ${data.data.createProjectTagRelationship.tag.name}`
        );
      })
      .catch(error => {
        message.error(`Add tag relationship failed, error message: ${error.message}`);
      });
  };

  removeRelationshipFromProject = async ({ id }) => {
    if (!id) {
      // dumb workaround for the issue of missing relationship ids (code does not set those ids for newly added tags)
      message.error('Please reload the form to remove newly added tags.')
      return Promise.resolve();
    }

    const { commonContainer } = this.props;
    return commonContainer
      .handleRequest(
        {
          type: "mutation",
          operation: "deleteProjectTagRelationship",
          fields: ["id", { tag: ["id", "name"] }]
        },
        {
          input: {
            value: {
              id
            },
            type: "DeleteProjectTagRelationshipInput",
            required: true
          }
        }
      )
      .then(data => {
        message.success(
          `Successfully Removed ${data.data.deleteProjectTagRelationship.tag.name}`
        );
      })
      .catch(error => {
        message.error(`Remove tag relationship failed, error message: ${error.message}`);
      });
  };

  addRelationshipToResource = async ({ tag, resource }) => {
    const { commonContainer } = this.props;
    return commonContainer
      .handleRequest(
        {
          type: "mutation",
          operation: "createResourceTagRelationship",
          fields: ["id", { tag: ["id", "name"] }]
        },
        {
          input: {
            value: {
              resourceTagRelationshipTagId: tag,
              resourceTagRelationshipResourceId: resource
            },
            type: "CreateResourceTagRelationshipInput",
            required: true
          }
        }
      )
      .then(data => {
        message.success(
          `Successfully Added ${data.data.createResourceTagRelationship.tag.name}`
        );
      })
      .catch(error => {
        message.error(`Add tag relationship failed, error message: ${error.message}`);
      });
  };

  removeRelationshipFromResource = ({ id }) => {
    if (!id) {
      // dumb workaround for the issue of missing relationship ids (code does not set those ids for newly added tags)
      message.error('Please reload the form to remove newly added tags.')
      return Promise.resolve();
    }

    const { commonContainer } = this.props;
    return commonContainer
      .handleRequest(
        {
          type: "mutation",
          operation: "deleteResourceTagRelationship",
          fields: ["id", { tag: ["id", "name"] }]
        },
        {
          input: {
            value: {
              id
            },
            type: "DeleteResourceTagRelationshipInput",
            required: true
          }
        }
      )
      .then(data => {
        message.success(
          `Successfully Removed ${data.data.deleteResourceTagRelationship.tag.name}`
        );
      })
      .catch(error => {
        message.error(`Remove tag relationship failed, error message: ${error.message}`);
      });
  };

  render() {
    const { placeholder } = this.props;
    const { selectedRelationships, categories, loading } = this.state;
    return (
      <Fragment>
        <AntTreeSelect
          disabled={loading}
          treeData={categories}
          value={!loading ? selectedRelationships.map(item => item.tag) : []}
          onChange={this.onChange}
          treeCheckable
          showCheckedStrategy={AntTreeSelect.SHOW_CHILD}
          searchPlaceholder={placeholder}
        />
        <Button type="link" icon="save" onClick={() => this.handleSave()}>
          Save {placeholder}
        </Button>
      </Fragment>
    );
  }
}

export default TreeSelect;
