import React, { Component, Fragment } from "react";
import { Switch, Route, withRouter, Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

// Ant Design Components
import { Layout, Menu, Icon, Typography } from "antd";

// Configuration
import config from "./config";

// Assets
import logo from "./assets/logo.svg";
import collapsedLogo from "./assets/character.svg";

// Pages
import ErrorPage from "./views/ErrorPage";
import AccountPage from "./views/AccountPage";

// Components
import ApplicationSelect from "./components/ApplicationSelect";

@inject("commonContainer")
@withRouter
@observer
class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  generatePath = title => `/${title.replace(/\s+/g, "-").toLowerCase()}`;

  render() {
    const { collapsed } = this.state;
    const { commonContainer } = this.props;
    if (commonContainer.authData.signInUserSession) {
      return (
        <Layout style={{ height: "100vh" }}>
          <Layout.Sider
            width={250}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
          >
            <ApplicationSelect
              buttonComponent={
                <img
                  src={collapsed ? collapsedLogo : logo}
                  alt="Logo"
                  style={{
                    height: collapsed ? 50 : 90,
                    display: "flex",
                    margin: collapsed ? "10px auto" : "5px auto",
                    cursor: "pointer"
                  }}
                />
              }
            />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
              {config.views.map((view, index) => (
                <Menu.Item key={index}>
                  <Link to={this.generatePath(view.title)}>
                    <Icon type={view.icon} />
                    <span className="nav-text">{view.title}</span>
                  </Link>
                </Menu.Item>
              ))}
              <Menu.Item onClick={() => commonContainer.handleLogout()}>
                <Icon type="logout" />
                <span className="nav-text">Logout</span>
              </Menu.Item>
            </Menu>
          </Layout.Sider>
          <Layout>
            <Layout.Content style={{ padding: 24, paddingBottom: 0, minHeight: "unset" }}>
              <Switch>
                {config.views.map((view, index) => (
                  <Route
                    key={index}
                    path={this.generatePath(view.title)}
                    render={() =>
                      view.component ? (
                        <view.component {...view} />
                      ) : (
                        <Fragment>
                          <Typography.Title level={2}>{view.title}</Typography.Title>
                          <p>This page is intended to be blank.</p>
                        </Fragment>
                      )
                    }
                  />
                ))}
                <Route path="/account" render={() => <Redirect to="/" />} />
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect to={this.generatePath(config.views[0].title)} />
                  )}
                />
                <Route exact path="*" component={ErrorPage} />
              </Switch>
            </Layout.Content>
            <Layout.Footer style={{ textAlign: "center", padding: 24, paddingTop: 0 }}>
              &copy; {new Date().getFullYear()} {config.app.info.name}. All Rights
              Reserved. For Internal Use Only.
            </Layout.Footer>
          </Layout>
        </Layout>
      );
    }
    return (
      <Switch>
        <Route path="/account/login" component={AccountPage} />
        <Route exact path="*" render={() => <Redirect to="/account/login" />} />
      </Switch>
    );
  }
}

export default Application;
