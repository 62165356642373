import React, { Component, PureComponent, Fragment } from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";

import ThumbnailUpload from "./ThumbnailUpload";
import Switch from "./Switch";

class MetadataValueInput extends PureComponent {
  render() {
    const { value, name, onChange } = this.props;
    if (value) {
      if (value.startsWith("http") || value === "#") {
        return (
          <ThumbnailUpload
            value={value}
            onChange={imageUrl => onChange(name, imageUrl)}
          />
        );
      }
      if (value === "true" || value === "false") {
        return (
          <Switch
            value={value === "true"}
            onChange={checked => onChange(name, checked.toString())}
          />
        );
      }
    }
    return (
      <Input.TextArea
        autoSize={{ minRows: 1 }}
        placeholder="Field Value"
        value={value}
        onChange={event => onChange(name, event.target.value)}
      />
    );
  }
}

export default class MetadataInput extends Component {
  constructor() {
    super();
    this.state = {
      fields: [],
      name: "",
      value: "",
      type: undefined,
      status: "",
      message: ""
    };
  }

  componentDidMount = () => {
    this.initalise();
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.initalise();
    }
  }

  initalise = () => {
    const { value } = this.props;
    this.setState({
      fields: value ? JSON.parse(value) : {}
    });
  };

  addField = () => {
    const { fields, name } = this.state;
    if (fields[name]) {
      this.setState({
        status: "error",
        message: "Field Already Exists"
      });
    } else {
      this.setState(
        prevState => ({
          fields: {
            ...prevState.fields,
            [prevState.name]: prevState.value
          },
          name: "",
          value: "",
          type: undefined
        }),
        () => this.handleSave()
      );
    }
  };

  removeField = name => {
    this.setState(
      prevState => {
        const newFields = { ...prevState.fields };
        delete newFields[name];
        return { fields: newFields };
      },
      () => this.handleSave()
    );
  };

  handleNameChange = name => {
    this.setState(prevState => {
      if (prevState.fields[name] !== undefined) {
        return {
          name,
          status: "error",
          message: "Field Already Exists"
        };
      }
      if (/\s/.test(name)) {
        return {
          name,
          status: "error",
          message: "Invalid Field Name"
        };
      }
      return {
        name,
        status: "",
        message: ""
      };
    });
  };

  handleValueChange = value => {
    this.setState({ value });
  };

  handleTypeChange = type => {
    switch (type) {
      case "boolean":
        this.handleValueChange("false");
        break;
      case "image":
        this.handleValueChange("#");
        break;
      case "string":
      default:
        this.handleValueChange("");
        break;
    }
    this.setState({ type });
  };

  handleFieldChange = (name, value) => {
    this.setState(
      prevState => ({
        fields: { ...prevState.fields, [name]: value }
      }),
      () => this.handleSave()
    );
  };

  handleSave = () => {
    const { fields } = this.state;
    const { onChange } = this.props;
    onChange(JSON.stringify(fields));
  };

  render() {
    const { fields, name, type, status, message } = this.state;
    return (
      <Fragment>
        {Object.entries(fields).map(([fieldName, fieldValue]) => (
          <Row key={fieldName} gutter={16}>
            <Col span={8}>
              <Form.Item style={{ marginBottom: 0, paddingBottom: 16 }}>
                <Input value={fieldName} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item style={{ marginBottom: 0, paddingBottom: 16 }}>
                <MetadataValueInput
                  value={fieldValue}
                  name={fieldName}
                  onChange={this.handleFieldChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item style={{ marginBottom: 0, paddingBottom: 16 }}>
                <Button
                  type="link"
                  icon="minus-circle"
                  onClick={() => this.removeField(fieldName)}
                >
                  Remove Field
                </Button>
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              validateStatus={status}
              help={message}
              style={{ marginBottom: 0, paddingBottom: 16 }}
            >
              <Input
                placeholder="Field Name"
                value={name}
                onChange={event => this.handleNameChange(event.target.value)}
                onPressEnter={() => this.addField()}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item style={{ marginBottom: 0, paddingBottom: 16 }}>
              <Select
                placeholder="Field Type"
                value={type}
                onChange={newType => this.handleTypeChange(newType)}
              >
                <Select.Option value="string">String</Select.Option>
                <Select.Option value="boolean">Boolean</Select.Option>
                <Select.Option value="image">Image</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item style={{ marginBottom: 0, paddingBottom: 16 }}>
              <Button
                type="link"
                icon="plus-circle"
                onClick={() => this.addField()}
                disabled={status === "error" || name === "" || !type}
              >
                Add Field
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
